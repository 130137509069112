<template>
  <div v-show="url">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn :class="statusClasses" icon @click="toggle" v-on="on">
          <v-icon>fa-credit-card</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
    <div v-show="url" :class="{ expanded: expanded }" class="c-easypos-frame">
      <iframe id="easypos-frame-iframe" frameborder="0" height="1%" width="1%" />
    </div>
  </div>
</template>

<script>
  import { vxm } from '../store'

  const EasyposStartupTimeout = 3000

  export default {
    name: 'EasyposCashRegister',
    props: [],
    data: function () {
      return {
        dialog: true,
        expanded: false,
        isCardReaderReady: false,
        isEasyposStartupAttempted: false,
        isEasyposStartupTimeout: false,
        storeDetails: {},
      }
    },

    computed: {
      url() {
        // URL is in state and loaded on login
        return vxm.easypos.url
      },
      status() {
        if (!this.url) {
          // easypos not active on this site (no url)
          return 'inactive'
        }
        if (this.isCardReaderReady) {
          // loaded and fully functional (with card-reader)
          return 'ready'
        }
        if (this.isEasyposStartupAttempted) {
          // loaded but not fully functional (card-reader not ready)
          return 'loaded'
        }
        if (this.isEasyposStartupTimeout) {
          // no signs of life from easypos after timeout sec, probably iframe did not load (cert err?)
          return 'timeout'
        }
        return 'loading' // waiting for easypos to show signs of life (events)
      },
      statusClasses() {
        const classes = {}
        classes['status-' + this.status] = true
        return classes
      },
      tooltipText() {
        switch (this.status) {
          case 'loading':
            return 'Easypos is loading'
          case 'loaded':
            return 'Easypos is loaded, but card reader is not ready'
          case 'ready':
            return 'Easypos is ready'
          case 'timeout':
            return 'Easypos failed to start'
        }
        return null
      },
    },

    watch: {
      url: function (url) {
        if (url) {
          this.getEasyposFrame().contentWindow.location.replace(url)
        }
      },
      status: function (status) {
        this.debug('StatusChange:', status)
      },
    },

    created: function () {
      this.debug('>>> CREATED <<<')
      window.addEventListener('message', this.receiveMessage)
      setTimeout(() => {
        if (!this.isEasyposStartupAttempted) {
          this.isEasyposStartupTimeout = true
        }
      }, EasyposStartupTimeout)
    },

    destroyed: function () {
      this.debug('>>> DESTROYED <<<')
      window.removeEventListener('message', this.receiveMessage)
    },
    methods: {
      // Debug

      debug: function (msg, arg) {
        if (arg) {
          // eslint-disable-next-line no-console
          console.log('EASYPOS-VUE -', msg, arg)
        } else {
          // eslint-disable-next-line no-console
          console.log('EASYPOS-VUE -', msg)
        }
      },
      // Visibility

      toggle: function () {
        this.expanded = !this.expanded
      },
      showFrame: function () {
        this.expanded = true
      },
      hideFrame: function () {
        this.expanded = false
      },
      // Elements

      getEasyposFrame() {
        return document.getElementById('easypos-frame-iframe')
      },
      getLegacyFrame() {
        return document.getElementById('legacy-iframe')
      },
      // Store details

      getFullStoreDetailsFromEasyposEvent: function (eventData) {
        const isComplete = eventData.orgId && eventData.storeId && eventData.registerId
        return isComplete
          ? { orgId: eventData.orgId, storeId: eventData.storeId, registerId: eventData.registerId }
          : null
      },
      // Events

      postMessageToEasypos: function (message) {
        this.getEasyposFrame().contentWindow.postMessage(message, '*')
      },
      postMessageToLegacy: function (message) {
        this.getLegacyFrame().contentWindow.postMessage(message, '*')
      },
      receiveMessage: function (e) {
        if (!e.data || !e.data.type) {
          return
        }

        // Get store and register from any event as early as possible,
        // removing the need to configure this per site/machine.
        const storeDetails = this.getFullStoreDetailsFromEasyposEvent(e.data)
        if (storeDetails) {
          // NB: This is similar to easypos-ready; we must store it here an proxy on each eontyre-easypos-component startup
          this.storeDetails = storeDetails
          // However, should user have already opened the component, do also proxy the message directly
          const message = { type: 'easypos-store-details', data: this.storeDetails }
          this.postMessageToLegacy(message)
        }

        switch (e.data.type) {
          // Events from oX.eontyre.com - ie. mod_order js

          case 'LegacyFrameEasyposPostMessage':
            // Legacy code wants to postMessage to easypos
            this.debug('receiveMessage: LegacyFrameEasyposPostMessage:', e.data.message)
            this.postMessageToEasypos(e.data.message)
            break
          case 'LegacyFrameEasyposShowFrame':
            // Legacy code wants to show easypos frame
            this.debug('receiveMessage: LegacyFrameEasyposShowFrame')
            this.showFrame()
            break
          case 'LegacyFrameEasyposHideFrame':
            // Legacy code wants to hide easypos frame
            this.debug('receiveMessage: LegacyFrameEasyposHideFrame')
            this.hideFrame()
            break
          case 'LegacyFrameEasyposGetStartupState':
            // Legacy code started easypos knockout component, and we need to
            // proxy easypos-ready to it to tell it card reader is online (if it is).
            this.debug(
              'receiveMessage: LegacyFrameEasyposGetStartupState - isCardReaderReady=' +
              (this.isCardReaderReady ? 'true' : 'false'),
            )
            if (this.isCardReaderReady) {
              this.postMessageToLegacy({ type: 'easypos-ready' })
            }
            if (this.storeDetails) {
              const message = { type: 'easypos-store-details', data: this.storeDetails }
              // legacyFrame.contentWindow.postMessage(message, '*');
              this.postMessageToLegacy(message)
            }
            break

          // Events from easypos

          case 'easypos-ready':
            // We get 'easypos-ready' when card terminal init has completed.
            // Legacy code's easypos component will look for that event to see when
            // terminal is ready, but now that we only init EP once, it will
            // not occur to child frame at that later time.
            // So keep track of whether terminal is online and send the event later.
            // (ie. when we get LegacyFrameEasyposGetStartupState - see above)
            this.debug('receiveMessage: easypos-ready')
            this.isCardReaderReady = true
            break
          case 'easypos-offline':
            // In case it goes offline, handle that too
            this.debug('receiveMessage: easypos-offline')
            this.isCardReaderReady = false
            break
          default:
            this.debug('UNKNOWN receiveMessage: ' + e.data.type)
        } // end switch

        // Proxy all messages from the easypos frame to the legacy code knockout easypos component
        // This also includes the easypos-ready/easypos-offline messages handled above,
        // because they may in theory occur when the child frame easypos component has
        // started, in which case it will not run LegacyFrameEasyposGetStartupState to get
        // them, so they should be passed directly.

        const isEasyposMessage =
          e.data &&
          (e.data.type.indexOf('easypos') === 0 ||
            e.data.type === 'mirror-text' ||
            (e.data.storeId && e.data.registerId))

        if (isEasyposMessage) {
          this.debug('proxyMessage: ' + e.data.type, e.data)
          // Proxy message to legacy frame with knockout component
          this.postMessageToLegacy(e.data)
          // If we have any easypos-like event then log it so we know easypos has loaded
          this.isEasyposStartupAttempted = true
        }
      },
    },
  }
</script>

<style lang="sass">
  .c-easypos-frame

    #easypos-frame-iframe
      z-index: 0
      position: fixed
      bottom: 0
      left: 10px
      width: 1px
      height: 1px
      border: 0

    &.expanded
      box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)
      border-radius: 2px
      /*margin: 24px;*/
      position: fixed
      background: white
      z-index: 2
      width: 916px
      height: 590px
      top: 210px
      left: 50%
      margin-top: 0
      margin-left: -452px

      #easypos-frame-iframe
        background: white
        z-index: 3
        width: 916px
        height: 590px
        top: 210px
        left: 50%
        margin-top: 0
        margin-left: -452px

  .v-btn.status-loading .v-btn__content .v-icon
    color: #888888 !important

  .v-btn.status-loaded .v-btn__content .v-icon
    color: orange !important

  .v-btn.status-ready .v-btn__content .v-icon
    color: green !important

  .v-btn.status-timeout .v-btn__content .v-icon
    color: red !important
</style>
