

















































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import FetchData from './helpers/FetchData.vue'
import EasyposCashRegister from './EasyposCashRegister.vue'
import { vxm } from '@/store'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
import NavigationDualButton from '@/components/NavigationDualButton.vue'
import CustomerQueueControl from '@/components/customer-queue/CustomerQueueControl.vue'
import { getStyles } from '@/utils/styleFetcher'
import Logo from '@/components/Logo.vue'

@Component({
  computed: {
    vxm() {
      return vxm
    },
  },
  components: { Logo, NavigationDrawer, EasyposCashRegister, FetchData, NavigationDualButton, CustomerQueueControl },
})
export default class NavigationBar extends Vue {
  items: Object = {}
  navbarButtons: Array<Object> = []
  hasSearchBar = false
  hasNotifications = false
  hasCustomerQueue = false
  search = ''
  drawer = false
  $refs!: {
    searchInput: HTMLFormElement
  }

  @Prop({ default: false }) logoName: boolean
  @Prop({ default: false }) logoCenter: boolean
  @Prop({ default: false }) searchEnabled: boolean

  get visible() {
    const hide = this.$route.meta && this.$route.meta.hideNavBar
    return !hide
  }

  get style() {
    return getStyles()
  }

  get sitePrefix() {
    return '/' + this.$route.params.siteId
  }

  get user() {
    return vxm.user
  }

  get notifications() {
    return vxm.notifications
  }

  get queueWaiting() {
    return vxm.queue.waitingCount
  }

  set queueWaiting(val) {}

  get loading() {
    return this.$store.state.loading
  }

  bindUrl(url) {
    const isAbsolute = /^([a-z]+:\/\/|\/\/)/i
    if (isAbsolute.test(url)) {
      return {
        href: url,
      }
    }
    return {
      to: this.appendSitePrefix(url),
    }
  }

  appendSitePrefix(url) {
    return this.sitePrefix + url
  }

  collapse(e) {
    // $(".collapse.show:not(" + e.currentTarget.getAttribute("data-target") + ")").collapse("hide");
  }

  notificationsBtn() {
    vxm.overlay.open('notifications')
  }

  get showQueue() {
    return vxm.queue.isVisible
  }

  queueBtn() {
    vxm.queue.toggle()
  }

  logoutBtn(e) {
    e.preventDefault()
    this.$store.dispatch('logout').then(() => {
      this.$router.push({
        name: 'Login',
      })
    })
  }

  doSearch() {
    this.$router.push({
      name: 'Search',
      params: { query: this.search },
    })
    this.search = ''
    this.$refs.searchInput.blur()
  }

  fetchNavbar() {
    if (this.user.hasToken) {
      this.$axios.get('/v3/user/navbar').then((response) => {
        this.items = response.data.data.nav[0]
        this.navbarButtons = response.data.data.navbarButtons
        this.hasSearchBar = response.data.data.hasSearchBar
        this.hasCustomerQueue = response.data.data.hasCustomerQueue
        this.hasNotifications = response.data.data.hasNotifications
      })
    } else {
      this.items = {}
    }
  }

  @Watch('user.token', { immediate: true })
  onUserLoggedIn() {
    this.fetchNavbar()
  }
}
